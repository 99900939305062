import React from "react";
import { Navbar } from "reactstrap";
import classnames from "classnames";
import NavbarUser from "./NavbarUser";
import { Menu, PlusCircle, Radio, AlertTriangle } from "react-feather";
import { connect } from "react-redux";
import {
  UserAction,
  FetchFcmStatusAction,
  FlaggingKeywordsAction,
  POST_CATEGORY,
  USER,
} from "../../../redux/actions/app/appActions";
import NewPostModal from "../../../components/modals/NewPostModal";
import { GetUnreadCountAction } from "../../../redux/actions/notifications/notificationsActions";
import { GetLiveModelsAction } from "../../../redux/actions/liveActions/liveActions";
import { timelineMap } from "../../../utils/utils";

const colorsArr = ["primary", "danger", "success", "info", "warning", "dark"];
const navbarTypes = ["floating", "static", "sticky", "hidden"];
const axios = require("axios");

class ThemeNavbar extends React.Component {
  state = {
    modal: false,
  };

  signal = axios.CancelToken.source();

  storageListener = (e) => {
    if (e.key === "user_id") {
      window.location.reload();
    }
  };

  sendBeacon = async () => {
    const formData = new FormData();
    formData.append(
      "is_tab_visible",
      document.visibilityState === "visible" ? "1" : "0"
    );
    formData.append("user_id", window.localStorage.getItem("user_id"));

    navigator.sendBeacon(
      `${process.env.REACT_APP_APIS}v1/mux/set-tab-visibility`,
      formData
    );

    if (
      document.visibilityState === "visible" &&
      window.location.href.toLowerCase().includes("/home")
    ) {
      this.props.getLiveModels();
    }
  };

  async componentDidMount() {
    window.addEventListener("storage", this.storageListener, false);

    this.props.getUser();
    this.props.getUnreadNotificationCount(this.props.props.history);
    this.props.fetchFcmStatus();
    this.props.getFlaggingKeywords();

    document.addEventListener("visibilitychange", this.sendBeacon);
  }

  componentWillUnmount() {
    window.removeEventListener("storage", this.storageListener, false);
    document.removeEventListener("visibilitychange", this.sendBeacon);
  }

  FeedHandler = () => {
    if (this.props.props.match.url === "/Home") {
      this.props.FeedHandler();
    } else {
      this.props.props.history.push("/Home");
      this.props.FeedHandler();
    }
  };

  PublicHandler = () => {
    if (this.props.props.match.url === "/Home") {
      this.props.PublicHandler();
    } else {
      this.props.props.history.push("/Home");
      this.props.PublicHandler();
    }
  };

  PrivateHandler = () => {
    if (this.props.props.match.url === "/Home") {
      this.props.PrivateHandler();
    } else {
      this.props.props.history.push("/Home");
      this.props.PrivateHandler();
    }
  };

  closeNewPostModal = () => {
    this.setState({ modal: false });
  };

  render() {
    return (
      <>
        <div className="content-overlay" />
        <div className="header-navbar-shadow" />
        <Navbar
          className={classnames(
            "header-navbar navbar-expand-lg navbar navbar-with-menu navbar-shadow",
            {
              "navbar-light":
                this.props.navbarColor === "default" ||
                !colorsArr.includes(this.props.navbarColor),
              "navbar-dark": colorsArr.includes(this.props.navbarColor),
              "bg-primary":
                this.props.navbarColor === "primary" &&
                this.props.navbarType !== "static",
              "bg-danger":
                this.props.navbarColor === "danger" &&
                this.props.navbarType !== "static",
              "bg-success":
                this.props.navbarColor === "success" &&
                this.props.navbarType !== "static",
              "bg-info":
                this.props.navbarColor === "info" &&
                this.props.navbarType !== "static",
              "bg-warning":
                this.props.navbarColor === "warning" &&
                this.props.navbarType !== "static",
              "bg-dark":
                this.props.navbarColor === "dark" &&
                this.props.navbarType !== "static",
              "d-none":
                this.props.navbarType === "hidden" && !this.props.horizontal,
              "floating-nav":
                (this.props.navbarType === "floating" &&
                  !this.props.horizontal) ||
                (!navbarTypes.includes(this.props.navbarType) &&
                  !this.props.horizontal),
              "navbar-static-top":
                this.props.navbarType === "static" && !this.props.horizontal,
              "fixed-top":
                this.props.navbarType === "sticky" || this.props.horizontal,
              scrolling: this.props.horizontal && this.props.scrolling,
            }
          )}
        >
          <div className="navbar-wrapper">
            <div
              className="navbar-container content"
              style={{
                boxShadow: "0px 10px 20px #3d3d3d16",
                minHeight: "62.29px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div
                className="navbar-collapse d-flex justify-content-between align-items-center"
                id="navbar-mobile"
              >
                <Menu
                  className="ficon"
                  style={{
                    color: this.props.theme === "dark" ? "#fff" : "#000",
                  }}
                  onClick={this.props.sidebarVisibility}
                />
                <div className="postCat">
                  <span
                    className={`${
                      this.props.postCat === timelineMap.feed &&
                      (this.props.theme === "light" ||
                        this.props.theme === "Light")
                        ? "postCatActive"
                        : ""
                    }${
                      this.props.postCat === timelineMap.feed &&
                      this.props.theme === "dark"
                        ? "postCatActiveDark"
                        : ""
                    }`}
                    onClick={() => this.FeedHandler()}
                  >
                    Feed
                  </span>
                  <span
                    className={`${
                      this.props.postCat === timelineMap.premium &&
                      (this.props.theme === "light" ||
                        this.props.theme === "Light")
                        ? "postCatActive"
                        : ""
                    }${
                      this.props.postCat === timelineMap.premium &&
                      this.props.theme === "dark"
                        ? "postCatActiveDark"
                        : ""
                    }`}
                    onClick={() => this.PublicHandler()}
                  >
                    Premium
                  </span>
                  {this.props.user?.isApprovedModel === 1 && (
                    <span
                      className={`${
                        this.props.postCat === timelineMap.archive &&
                        (this.props.theme === "light" ||
                          this.props.theme === "Light")
                          ? "postCatActive"
                          : ""
                      }${
                        this.props.postCat === timelineMap.archive &&
                        this.props.theme === "dark"
                          ? "postCatActiveDark"
                          : ""
                      }`}
                      onClick={() => this.PrivateHandler()}
                    >
                      Archive
                    </span>
                  )}
                  {this.props.user?.isApprovedModel === 1 ? (
                    !this.props.isModerator ||
                    (this.props.isModerator &&
                      this.props.moderatorPermissions.writePost) ? (
                      <PlusCircle
                        style={{
                          cursor: "pointer",
                          color:
                            this.props.theme === "dark" ? "#d3af37" : "#800080",
                        }}
                        onClick={() => this.setState({ modal: true })}
                      />
                    ) : null
                  ) : null}
                  {this.props.user?.isApprovedModel === 1 &&
                    this.props.user?.is_go_live === 1 &&
                    !this.props.isModerator && (
                      <span
                        onClick={() => {
                          this.props.props.history.push("/streaming");
                        }}
                        style={{ marginLeft: "1.5rem" }}
                      >
                        <span className="live-icon-container">
                          <Radio
                            style={{
                              cursor: "pointer",
                              color:
                                this.props.theme === "dark"
                                  ? "#d3af37"
                                  : "#800080",
                            }}
                          />
                          {this.props.isUserLive ? (
                            <span className="live-indicator"></span>
                          ) : null}
                        </span>
                        <span
                          style={{
                            color:
                              this.props.theme === "dark"
                                ? "#d3af37"
                                : "#800080",
                            marginLeft: "5px",
                          }}
                        >
                          Go Live!
                        </span>
                      </span>
                    )}
                </div>
                {this.props.horizontal ? (
                  <div className="logo d-flex align-items-center">
                    <div className="brand-logo mr-50"></div>
                    <h2
                      className="mb-0"
                      style={{
                        color: this.props.theme === "dark" ? "#fff" : "#000",
                      }}
                    >
                      Feature
                    </h2>
                    <h2 className="mb-0" style={{ color: "#d3af37" }}>
                      Fans
                    </h2>
                  </div>
                ) : null}

                {/* {this.state.mainLoading && ( */}
                <NavbarUser
                  props={this.props}
                  handleAppOverlay={this.props.handleAppOverlay}
                  changeCurrentLang={this.props.changeCurrentLang}
                  user={this.props.user}
                  userImg={this.props.user?.image}
                />
                {/* )} */}
              </div>
            </div>
          </div>
        </Navbar>

        {this.props?.props?.location?.pathname !== "/streaming" &&
        this.props.isUserLive &&
        this.props.isUserLiveFromThisSession ? (
          <div className="live-stream-alert">
            <p
              style={{
                color: this.props.theme === "Light" ? "#800180" : "#c9a737",
              }}
            >
              <AlertTriangle size={26} stroke="#ea3939" />
              {"    "}
              You are currently streaming live, please do not refresh your page
              or close your tab.
            </p>
          </div>
        ) : null}

        <NewPostModal
          isModalOpen={this.state.modal}
          closeModal={this.closeNewPostModal}
          history={this.props.props.history}
        />
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    postCat: state.app.postCat,
    user: state.app.user,
    isModerator: state.app.isModerator,
    moderatorPermissions: state.app.moderatorPermissions,
    theme: state.customizer.theme,
    isUserLive: state.live.isUserLive,
    isUserLiveFromThisSession: state.live.isUserLiveFromThisSession,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    FeedHandler: () =>
      dispatch({ type: POST_CATEGORY, value: timelineMap.feed }),
    PublicHandler: () =>
      dispatch({ type: POST_CATEGORY, value: timelineMap.premium }),
    PrivateHandler: () =>
      dispatch({ type: POST_CATEGORY, value: timelineMap.archive }),
    UserHandler: (value) => dispatch({ type: USER, value: value }),
    Notifications: (value) => dispatch({ type: "NOTIFICATION", value: value }),
    getUser: UserAction(dispatch),
    getUnreadNotificationCount: (history) => {
      GetUnreadCountAction(dispatch, history)();
    },
    getLiveModels: GetLiveModelsAction(dispatch),
    fetchFcmStatus: FetchFcmStatusAction(dispatch),
    getFlaggingKeywords: FlaggingKeywordsAction(dispatch),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(ThemeNavbar);
