import React from "react";
import {
  Home,
  User,
  Video,
  MessageSquare,
  Search,
  Folder,
} from "react-feather";
import { ReactComponent as TopCreator } from "../assets/img/svg/topCreator.svg";
const horizontalMenuConfig = [
  {
    id: "home",
    title: "Home",
    type: "item",
    icon: <Home size={20} />,
    navLink: "/Home",
  },
  {
    id: "page2",
    title: "Profile",
    type: "item",
    icon: <User size={20} />,
    navLink: "/Profile",
  },
  //   {
  //     id: "shop",
  //     title: "Features Shops",
  //     type: "item",
  //     icon: <ShoppingBag size={20} />,
  //     navLink: "/shop",
  //   },
  //  {
  //   id: 'live',
  //   title: 'Live',
  //   type: 'item',
  //   icon: <Radio size={20} />,
  //   navLink: '/Live',
  //  },
  {
    id: "VOD",
    title: "VOD",
    type: "item",
    icon: <Video size={20} />,
    navLink: "/VOD",
  },
  {
    id: "Messaging",
    title: "Messaging",
    type: "item",
    icon: <MessageSquare size={20} />,
    navLink: "/chat",
  },
  {
    id: "Search",
    title: "Search",
    type: "item",
    icon: <Search size={20} />,
    navLink: "/Search",
  },
  {
    id: "Creators",
    title: "Top Creators",
    type: "item",
    icon: (
      <TopCreator
        className="top-creator-nav"
        style={{ width: "20px", height: "20px" }}
      />
    ),
    navLink: "/Creators",
  },
  {
    id: "MyData",
    title: "My Items",
    type: "item",
    icon: <Folder size={20} />,
    navLink: "/my-items",
  },
];

export default horizontalMenuConfig;
